html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #2d356b;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #d9d9d9a9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: var(--imp-text-color);
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem !important;
    font-size: 1.4rem !important;
    background-color: #d9d9d9 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  font-weight: 600;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.myAvtar {
  justify-content: center !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  padding-top: 100px !important;
  padding-bottom: 20px !important;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--imp-text-color);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--imp-text-color);
}

.home-social-icons:hover {
  color: var(--imp-text-color);
  box-shadow: 0 0 5px var(--imp-text-color);
  text-shadow: 0 0 2px var(--imp-text-color);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: var(--imp-text-color);
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 80px;
  padding-bottom: 30px;
}

.project-heading {
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

.starry-me-col-1 {
  padding: 69px 108px !important;
}

@media (max-width: 991px) {
  .starry-me-col-1 {
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 767px) {
  .starry-me-col-1 {
    padding-bottom: 16px !important;
  }
}

.swapy-col-2 {
  padding: 28px 108px !important;
}

@media (max-width: 991px) {
  .swapy-col-2 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 80px !important;
  padding-bottom: 30px !important;
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}
